export enum SoftwareSlug {
  CellRanger = "cell-ranger",
  CellRangerARC = "cell-ranger-arc",
  CellRangerATAC = "cell-ranger-atac",
  CloudAnalysis = "cloud-analysis",
  CustomPanelDesigner = "custom-panel-designer",
  LoupeBrowser = "loupe-browser",
  LoupeBrowserVDJ = "loupe-vdj-browser",
  SpaceRanger = "space-ranger",
  XeniumExplorer = "xenium-explorer",
  XeniumOnboardAnalysis = "xenium-onboard-analysis",
  XeniumPanelDesigner = "xenium-panel-designer",
  XeniumRanger = "xenium-ranger",
}

export const SOFTWARE_SLUGS = [
  SoftwareSlug.CloudAnalysis,
  SoftwareSlug.CellRanger,
  SoftwareSlug.CellRangerATAC,
  SoftwareSlug.CellRangerARC,
  SoftwareSlug.SpaceRanger,
  SoftwareSlug.LoupeBrowser,
  SoftwareSlug.LoupeBrowserVDJ,
  SoftwareSlug.XeniumExplorer,
  SoftwareSlug.XeniumPanelDesigner,
  SoftwareSlug.XeniumRanger,
  SoftwareSlug.XeniumOnboardAnalysis,
];
