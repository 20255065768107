import { mediaDesktop, mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, ReactNode } from "react";

import TopBar from "../TopBar";

const mastheadCss = css`
  align-items: center;
  background: linear-gradient(#ebf4fc, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;

  @media (max-width: ${mediaPhoneOnly}) {
    height: 100%;
  }
`;

interface Props {
  children: ReactNode;
}

const HomepageMasthead: FunctionComponent<Props> = ({ children }) => {
  return (
    <header css={mastheadCss}>
      <div
        css={css`
          max-width: 1200px;
          width: 100%;
          box-sizing: border-box;

          @media (max-width: ${mediaDesktop}) {
            padding: 0 1.5rem;
          }
        `}
      >
        <TopBar backgroundColor={"transparent"} mode={"light"} />
      </div>

      {children}
    </header>
  );
};

export default HomepageMasthead;
