import { colorSteelLightest } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import Text from "../Text";

const FormerProductTag = ({ name }: { name: string }) => {
  return (
    <div
      css={css`
        align-items: center;
        background: ${colorSteelLightest};
        border-radius: 6px;
        display: flex;
        gap: 0.25rem;
        justify-content: center;
        padding: 6px 12px;
        width: fit-content;
      `}
    >
      <Text as={"span"} color={"steelDark"} size={"xsmall"} weight={"medium"}>
        {"Formerly"}
      </Text>
      <Text
        as={"span"}
        color={"steelDarker"}
        size={"xsmall"}
        weight={"semibold"}
      >
        {name}
      </Text>
    </div>
  );
};

export default FormerProductTag;
