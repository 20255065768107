export enum Product {
  CytAssistSpatialGeneExpression = "CytAssist Spatial Gene Expression",
  InSituGeneExpression = "In Situ Gene Expression",
  FlexGeneExpression = "Flex Gene Expression",
  EpiATAC = "Epi ATAC",
  EpiMultiome = "Epi Multiome ATAC + Gene Expression",
  SingleCellATAC = "Single Cell ATAC",
  SingleCellGeneExpression = "Single Cell Gene Expression",
  UniversalThreePrimeGeneExpression = "Universal 3' Gene Expression",
  UniversalFivePrimeGeneExpression = "Universal 5' Gene Expression",
  SingleCellGeneExpressionFlex = "Single Cell Gene Expression Flex",
  SingleCellImmuneProfiling = "Single Cell Immune Profiling",
  SingleCellMultiomeATACPlusGeneExpression = "Single Cell Multiome ATAC + Gene Expression",
  SpatialGeneAndProteinExpression = "Spatial Gene and Protein Expression",
  SpatialGeneExpressionFFPE = "Spatial Gene Expression for FFPE",
  SpatialGeneExpressionFreshFrozen = "Spatial Gene Expression for Fresh Frozen",
  SpatialGeneExpressionHD = "HD Spatial Gene Expression",
  TargetedGeneExpression = "Targeted Gene Expression",
}

export type Software =
  | "Cell Ranger ARC"
  | "Cell Ranger ATAC"
  | "Cell Ranger DNA"
  | "Cell Ranger VDJ"
  | "Cell Ranger"
  | "Cloud Analysis"
  | "Custom Panel Designer"
  | "Long Ranger"
  | "Loupe Browser"
  | "Loupe V(D)J Browser"
  | "Space Ranger"
  | "Supernova"
  | "Xenium Explorer"
  | "Xenium Panel Designer"
  | "Xenium Onboard Analysis"
  | "Xenium Ranger";

export enum ProductNameForSupport {
  FixedRNAProfiling = "Single Cell Gene Expression Flex",
}

export enum ProductSlug {
  CytAssistSpatialGeneExpression = "cytassist-spatial-gene-expression",
  FlexGeneExpression = "flex-gene-expression",
  EpiATAC = "epi-atac",
  EpiMultiome = "epi-multiome",
  InSituGeneExpression = "in-situ-gene-expression",
  SingleCellATAC = "single-cell-atac",
  SingleCellGeneExpression = "single-cell-gene-expression",
  UniversalThreePrimeGeneExpression = "universal-three-prime-gene-expression",
  UniversalFivePrimeGeneExpression = "universal-five-prime-gene-expression",
  SingleCellGeneExpressionFlex = "single-cell-gene-expression-flex",
  SingleCellImmuneProfiling = "single-cell-immune-profiling",
  SingleCellMultiomeATACPlusGeneExpression = "single-cell-multiome-atac-plus-gene-expression",
  SpatialGeneAndProteinExpression = "spatial-gene-and-protein-expression",
  SpatialGeneExpressionFFPE = "spatial-gene-expression-ffpe",
  SpatialGeneExpressionFreshFrozen = "spatial-gene-expression-fresh-frozen",
  SpatialGeneExpressionHD = "spatial-gene-expression-hd",
  TargetedGeneExpression = "targeted-gene-expression",
}

export enum DiscontinuedProductSlug {
  DeNovoAssembly = "de-novo-assembly",
  GenomeAndExome = "genome-exome",
  SingleCellCNV = "single-cell-cnv",
}

export enum Instrument {
  ChromiumConnect = "Chromium Connect",
  ChromiumController = "Chromium Controller",
  ChromiumXSeries = "Chromium X Series",
  VisiumCytAssist = "Visium CytAssist",
  XeniumAnalyzer = "Xenium Analyzer",
}

export enum InstrumentSlug {
  ChromiumConnect = "chromium-connect",
  ChromiumController = "chromium-controller",
  ChromiumXSeries = "chromium-x-series",
  VisiumCytAssist = "visium-cytassist",
  XeniumAnalyzer = "xenium-analyzer",
}

export enum InstrumentName {
  ChromiumConnect = "Chromium Connect",
  ChromiumController = "Chromium Controller",
  ChromiumXSeries = "Chromium X Series",
  VisiumCytAssist = "Visium CytAssist",
  XeniumAnalyzer = "Xenium Analyzer",
}

export enum InstrumentNameForSupport {
  ChromiumConnect = "Chromium Connect",
  ChromiumController = "Chromium Controller",
  ChromiumXSeries = "Chromium X Series",
  VisiumCytAssist = "Visium CytAssist",
  XeniumAnalyzer = "Xenium Analyzer",
}

export const INSTRUMENT_SLUGS = [
  InstrumentSlug.ChromiumXSeries,
  InstrumentSlug.ChromiumController,
  InstrumentSlug.ChromiumConnect,
  InstrumentSlug.VisiumCytAssist,
  InstrumentSlug.XeniumAnalyzer,
];

// this is a special case where there is naming variation between support and marketing for the instrument
export const INSTRUMENT_NAME_TO_INSTRUMENT_NAME_FOR_SUPPORT: Record<
  InstrumentName,
  InstrumentNameForSupport
> = {
  [InstrumentName.ChromiumConnect]: InstrumentNameForSupport.ChromiumConnect,
  [InstrumentName.ChromiumController]:
    InstrumentNameForSupport.ChromiumController,
  [InstrumentName.ChromiumXSeries]: InstrumentNameForSupport.ChromiumXSeries,
  [InstrumentName.VisiumCytAssist]: InstrumentNameForSupport.VisiumCytAssist,
  [InstrumentName.XeniumAnalyzer]: InstrumentNameForSupport.XeniumAnalyzer,
};

export const PRODUCT_NAME_TO_PRODUCT_NAME_FOR_SUPPORT: Record<string, Product> =
  {
    [Product.InSituGeneExpression]: Product.InSituGeneExpression,
    [Product.SingleCellATAC]: Product.SingleCellATAC,
    [Product.FlexGeneExpression]: Product.FlexGeneExpression,
    [Product.EpiATAC]: Product.EpiATAC,
    [Product.EpiMultiome]: Product.EpiMultiome,
    [Product.UniversalThreePrimeGeneExpression]:
      Product.UniversalThreePrimeGeneExpression,
    [ProductNameForSupport.FixedRNAProfiling]:
      Product.SingleCellGeneExpressionFlex,
    [Product.SingleCellGeneExpression]: Product.SingleCellGeneExpression,
    [Product.SingleCellImmuneProfiling]: Product.SingleCellImmuneProfiling,
    [Product.SingleCellMultiomeATACPlusGeneExpression]:
      Product.SingleCellMultiomeATACPlusGeneExpression,
    [Product.SpatialGeneAndProteinExpression]:
      Product.SpatialGeneAndProteinExpression,
    [Product.SpatialGeneExpressionFFPE]: Product.SpatialGeneExpressionFFPE,
    [Product.SpatialGeneExpressionFreshFrozen]:
      Product.SpatialGeneExpressionFreshFrozen,
    [Product.TargetedGeneExpression]: Product.TargetedGeneExpression,
    [Product.CytAssistSpatialGeneExpression]:
      Product.CytAssistSpatialGeneExpression,
    [Product.SpatialGeneExpressionHD]: Product.SpatialGeneExpressionHD,
  };

export const REVALIDATE_TIME = 2592000; // 30 days
