import { mediaPhoneOnly } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { forwardRef } from "react";

import Text from "../Text";
import { SoftwareSlug } from "@10xdev/types/constants/Support";
import ProductCard from "./ProductCard";

interface Props {
  softwares: {
    name: string | null;
    shortDescription: string | null;
    slug: string;
    supportImage: string | null;
    supportLink: string | null;
  }[];
}

const SoftwareSelect = forwardRef<HTMLDivElement, Props>(
  ({ softwares }, ref) => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          padding: 4rem 1.5rem;
        `}
        ref={ref}
      >
        <div
          css={css`
            max-width: 1200px;
          `}
        >
          <Text
            as={"div"}
            color={"steelDarkest"}
            css={css`
              margin-bottom: 3rem;
            `}
            size={"xlarge"}
            weight={"semibold"}
          >
            {"Software support"}
          </Text>
          <ul
            className={"InstrumentItems"}
            css={css`
              column-gap: 76px;
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              list-style: none;
              margin: 0;
              padding: 0;
              row-gap: 48px;
              @media (max-width: ${mediaPhoneOnly}) {
                column-gap: 0px;
                grid-template-columns: repeat(1, 100%);
                row-gap: 1rem;
              }
            `}
          >
            {softwares.map((software) => {
              return (
                <li key={software.slug}>
                  <ProductCard
                    image={{
                      alt: software.name as string,
                      src: software.supportImage,
                    }}
                    link={software.supportLink as string}
                    subtext={software.shortDescription as string}
                    target={
                      software.slug === SoftwareSlug.XeniumExplorer ||
                      software.slug === SoftwareSlug.SpaceRanger ||
                      software.slug === SoftwareSlug.LoupeBrowser ||
                      software.slug === SoftwareSlug.LoupeBrowserVDJ ||
                      software.slug === SoftwareSlug.CloudAnalysis ||
                      software.slug === SoftwareSlug.XeniumRanger ||
                      software.slug === SoftwareSlug.CellRanger ||
                      software.slug === SoftwareSlug.XeniumOnboardAnalysis ||
                      software.slug === SoftwareSlug.XeniumPanelDesigner
                        ? "_self"
                        : "_blank"
                    }
                    title={software.name as string}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    );
  },
);

SoftwareSelect.displayName = "SoftwareSelect";

export default SoftwareSelect;
